import { useContext, useEffect, useRef, useState } from 'react';

import { GlobalContext, Input, Button, Alert } from 'lynkco-up-core';
import { ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { Divider } from '../../../components';
import { InputWrapper } from '../../shared';
import { getMaxLengthErrorMessage } from '../utils';
import { approveDriversLicense } from '../../../services/Applicants';
import { ApplicantQuery } from '../../../types';

function ApproveChecksContent({ applicantQuery }: { applicantQuery: ApplicantQuery }) {
  const { alert, loading } = useContext(GlobalContext);
  const [customerNumber, setCustomerNumber] = useState(applicantQuery.customerNumber);
  const [caseId, setCaseId] = useState(applicantQuery.caseId);
  const [isCustomerSuggested, setIsCustomerSuggested] = useState(applicantQuery.customerNumber);
  const [reason, setReason] = useState('');
  const [formErrors, setFormErrors] = useState({
    customerNumber: false,
    caseId: false,
    reason: false,
  });
  const [resultError, setResultError] = useState<null | string>(null);
  const isSubmitDisabled = !customerNumber || !caseId || !reason || Object.values(formErrors).some(error => error);
  const inputRef = useRef<null | HTMLInputElement>(null);

  async function handleSubmit() {
    if (isSubmitDisabled) {
      return;
    }

    loading.show();

    try {
      await approveDriversLicense(caseId, customerNumber, reason);
      alert.show('User drivers license approved.', 'success');
    } catch (error) {
      setResultError(error as string);
    }

    loading.hide();
  }

  function handleDismissCustomerSuggestion() {
    setIsCustomerSuggested('');
  }

  const handleChangeCustomerNumber: ReactInputEventHandler = e => {
    setCustomerNumber(e.currentTarget.value);
  };

  const handleChangeCaseId: ReactInputEventHandler = e => {
    setCaseId(e.currentTarget.value);
  };

  const handleChangeReason: ReactInputEventHandler = e => {
    setReason(e.currentTarget.value);
  };

  function handleDismissResultError() {
    setResultError(null);
  }

  useEffect(() => {
    setFormErrors({
      customerNumber: customerNumber.length > 50,
      caseId: caseId.length > 50,
      reason: reason.length > 150,
    });
  }, [customerNumber, caseId, reason]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div>
      <h2 className="font-medium text-xl mb-7">Approve drivers license</h2>
      {resultError && (
        <Alert
          type="error"
          heading="There was an error while trying to manually approve drivers license"
          body={`${resultError}`}
          onDismiss={handleDismissResultError}
        />
      )}
      {isCustomerSuggested && (
        <Alert
          type="success"
          heading="Suggested customer"
          body={`We selected customer ${applicantQuery.customerNumber} for you`}
          onDismiss={handleDismissCustomerSuggestion}
        />
      )}
      <Divider />
      <InputWrapper label="Customer number*">
        <Input
          type="text"
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCustomerNumber}
          errorText={getMaxLengthErrorMessage(50)}
          hasError={formErrors.customerNumber}
          ref={inputRef}
          value={customerNumber}
        />
      </InputWrapper>
      <Divider />
      <InputWrapper label="Case ID*">
        <Input
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCaseId}
          errorText={getMaxLengthErrorMessage(50)}
          hasError={formErrors.caseId}
          value={caseId}
        />
      </InputWrapper>
      <Divider />
      <InputWrapper label="Reason*">
        <Input
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeReason}
          errorText={getMaxLengthErrorMessage(150)}
          hasError={formErrors.reason}
          value={reason}
        />
      </InputWrapper>
      <Divider />
      <div className="flex justify-end mt-7">
        <Button onClick={() => handleSubmit()} isDisabled={isSubmitDisabled}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ApproveChecksContent;
