import { CoreProvider } from 'lynkco-up-core';

import Routes from './routing';

import './index.css';

function App(): JSX.Element {
  return (
    /** key type and props are a placeholder until CoreProvider gets updates */
    <div className="App w-screen h-screen">
      <CoreProvider key="" type="" props="">
        <Routes />
      </CoreProvider>
    </div>
  );
}

export default App;
