import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';

export const authConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
    authority: process.env.REACT_APP_AAD_AUTHORITY || '',
    redirectUri: '/blank.html',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [`api://${process.env.REACT_APP_WEB_API_ID}/CarSharing.Access.All`],
};
