import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

import { Alert, SlideOver } from 'lynkco-up-core';

import { ActionPanel, PageHeading } from '../../components';
import { usePanelResize } from '../../hooks';
import { useState } from 'react';
import { ApplicationProgressContent, ApproveChecksContent, ResetChecksContent } from '../../parts/Applicants';
import { ApplicantQuery } from '../../types';

enum OperationType {
  APPROVE_CHECKS = 'APPROVE_CHECKS',
  RESET_CHECKS = 'RESET_CHECKS',
  NONE = 'NONE',
  APPLICATION_PROGRESS = 'APPLICATION_PROGRESS',
}

function Applicants() {
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [operationType, setOperationType] = useState(OperationType.NONE);
  const slideOverSize = usePanelResize();

  const [searchQuery] = useSearchParams();
  const caseId = searchQuery.get('caseID') || '';
  const customerNumber = searchQuery.get('customerNumber') || '';
  const applicantQuery: ApplicantQuery = { caseId, customerNumber };

  function handleOpenApproveChecks() {
    setIsSlideOverOpen(true);
    setOperationType(OperationType.APPROVE_CHECKS);
  }

  function handleOpenResetChecks() {
    setIsSlideOverOpen(true);
    setOperationType(OperationType.RESET_CHECKS);
  }

  function handleOpenApplicationProgress() {
    setIsSlideOverOpen(true);
    setOperationType(OperationType.APPLICATION_PROGRESS);
  }

  function handleCloseSlideOver() {
    setIsSlideOverOpen(false);
    setOperationType(OperationType.NONE);
  }

  return (
    <div>
      <Helmet>
        <title>Applicants - Car Sharing</title>
      </Helmet>
      <PageHeading title="Applicants" description="View and manage applicants." />
      <div className="pt-7 px-7">
        <Alert
          type="info"
          heading="These actions only apply to Car Sharing"
          body="The actions in this tool are for managing applications for car sharing only"
        />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-7 p-7">
        <ActionPanel
          title="Approve drivers license checks"
          description="Approve as user to proceed in Onfido even though they have been rejected for some reason."
          buttonText="Get started"
          onClick={handleOpenApproveChecks}
          iconName={'check'}
          iconBg={'bg-teal-500'}
        />
        <ActionPanel
          title="User application progress"
          description="See the application status of a user signing up for car sharing as a borrower."
          buttonText="Get started"
          onClick={handleOpenApplicationProgress}
          iconName={'person'}
          iconBg={'bg-blue-500'}
        />
        <ActionPanel
          title="Reset drivers license checks"
          description="Reset a user in Onfido so that they can start from scratch."
          buttonText="Get started"
          onClick={handleOpenResetChecks}
          iconName={'schedule'}
          iconBg={'bg-gray-400'}
        />
      </div>
      <SlideOver
        isOpen={isSlideOverOpen && operationType === OperationType.APPROVE_CHECKS}
        onCancel={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        size={slideOverSize}>
        <ApproveChecksContent applicantQuery={applicantQuery} />
      </SlideOver>
      <SlideOver
        isOpen={isSlideOverOpen && operationType === OperationType.RESET_CHECKS}
        onCancel={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        size={slideOverSize}>
        <ResetChecksContent applicantQuery={applicantQuery} />
      </SlideOver>
      <SlideOver
        isOpen={isSlideOverOpen && operationType === OperationType.APPLICATION_PROGRESS}
        onCancel={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        size={slideOverSize}>
        <ApplicationProgressContent applicantQuery={applicantQuery} />
      </SlideOver>
    </div>
  );
}

export default Applicants;
