import { Button, Icon, Panel } from 'lynkco-up-core';
import { IconName } from 'lynkco-up-core/dist/types/components/Icon';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';

import './actionPanel.css';

type ActionPanelProps = {
  title: string;
  iconName: IconName;
  description: string;
  /**
   * Tailwind "bg-color-value" class name
   */
  iconBg: string;
  buttonText: string;
  onClick: GenericFunction;
};

type PanelHeaderProps = {
  title: string;
  description: string;
  iconName: IconName;
  iconBg: string;
};

type PanelBodyProps = {
  buttonText: string;
  onClick: GenericFunction;
};

const PanelHeader = ({ title, description, iconName, iconBg }: PanelHeaderProps) => {
  return (
    <div>
      <div className="flex">
        <div className={`${iconBg} p-0.5 rounded-full`}>
          <Icon shape="circle" name={iconName} iconBackground="transparent" size="large" variant="light" />
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-medium">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

const PanelBody = ({ buttonText, onClick }: PanelBodyProps) => {
  return (
    <div className="flex justify-center action-panel-body my-2">
      <Button onClick={onClick} variant="transparent" size="large">
        <Icon shape="circle" name="start" iconBackground="transparent" variant="gray" padding={0} size="large" />
        <span className="ml-2">{buttonText}</span>
      </Button>
    </div>
  );
};

const ActionPanel = ({ title, description, iconName, iconBg, buttonText, onClick }: ActionPanelProps) => {
  return (
    <div className="action-panel">
      <Panel
        variant="with-header"
        header={<PanelHeader title={title} description={description} iconName={iconName} iconBg={iconBg} />}>
        <PanelBody buttonText={buttonText} onClick={onClick} />
      </Panel>
    </div>
  );
};

export default ActionPanel;
