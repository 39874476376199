import { ComponentWithChildren } from '../../types';

interface PageHeadingProps extends ComponentWithChildren {
  title: string;
  description: string;
}

function PageHeading({ title, description, children }: PageHeadingProps) {
  return (
    <div className="p-7 border-b border-gray-200 flex justify-between items-center">
      <div>
        <h3 className="text-xl leading-6 font-bold text-gray-900">{title}</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">{description}</p>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default PageHeading;
