import { useContext, useEffect, useRef, useState } from 'react';

import { Alert, Button, GlobalContext, Input } from 'lynkco-up-core';
import { ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { deleteUserData } from '../../../services/GDPR';
import { ComponentWithChildren } from '../../../types';

interface InputWrapperProps extends ComponentWithChildren {
  label: string;
}

function Divider() {
  return <div className="border-t mt-3.5 mb-3.5"></div>;
}

function InputWrapper({ label, children }: InputWrapperProps) {
  return (
    <div className="flex justify-between items-center">
      <p className="text-xs text-gray-500">{label}</p>
      {children}
    </div>
  );
}

function DeleteUserDataContent() {
  const { alert, loading } = useContext(GlobalContext);
  const [customerNumber, setCustomerNumber] = useState('');
  const [caseId, setCaseId] = useState('');
  const [formErrors, setFormErrors] = useState({
    customerNumber: false,
    caseId: false,
  });
  const maxLengthErrorMessage = 'This value cannot exceed 50 characters.';
  const isSubmitDisabled = !customerNumber || !caseId || Object.values(formErrors).some(error => error);
  const [resultError, setResultError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeCustomerNumber: ReactInputEventHandler = e => {
    setCustomerNumber(e.currentTarget.value);
  };

  const handleChangeCaseId: ReactInputEventHandler = e => {
    setCaseId(e.currentTarget.value);
  };

  async function handleSubmit() {
    if (isSubmitDisabled) {
      return;
    }

    if (resultError) {
      setResultError(null);
    }

    loading.show();

    try {
      await deleteUserData(caseId, customerNumber);
      alert.show('Data successfully deleted.', 'success');
    } catch (error) {
      setResultError(error as string);
    }

    loading.hide();
  }

  function handleDismissResultError() {
    setResultError(null);
  }

  useEffect(() => {
    setFormErrors({
      customerNumber: customerNumber.length > 50,
      caseId: caseId.length > 50,
    });
  }, [customerNumber, caseId]);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  return (
    <div>
      <h2 className="font-medium text-xl mb-7">Delete user data</h2>
      {resultError && (
        <Alert
          type="error"
          heading="There was an error while trying to delete user data"
          body={`${resultError}`}
          onDismiss={handleDismissResultError}
        />
      )}
      <Divider />
      <InputWrapper label="Customer number*">
        <Input
          ref={inputRef}
          type="text"
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCustomerNumber}
          errorText={maxLengthErrorMessage}
          hasError={formErrors.customerNumber}
        />
      </InputWrapper>
      <Divider />
      <InputWrapper label="Case ID">
        <Input
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCaseId}
          errorText={maxLengthErrorMessage}
          hasError={formErrors.caseId}
        />
      </InputWrapper>
      <Divider />
      <div className="flex justify-end mt-7">
        <Button onClick={() => handleSubmit()} isDisabled={isSubmitDisabled}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default DeleteUserDataContent;
