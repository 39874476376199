import { ComponentWithChildren } from '../types';

interface InputWrapperProps extends ComponentWithChildren {
  label: string;
}

export function InputWrapper({ label, children }: InputWrapperProps) {
  return (
    <div className="flex justify-between items-center">
      <p className="text-xs text-gray-500">{label}</p>
      {children}
    </div>
  );
}
