import { AxiosError } from 'axios';

import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';
import { UserData } from './types';

const endpointUrl = '/applicant-data/caseId';

async function getUserData(caseId: string, customerNumber: string): Promise<UserData> {
  const url = `${serviceConfig.endpoints.onfido.root}${endpointUrl}/${caseId}`;

  try {
    const response = await axiosInstance.get(url, {
      headers: {
        'X-CustomerNumber': customerNumber,
      },
    });

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new Error(err.response?.data.message);
    }

    throw new Error('Failed to get user data');
  }
}

export default getUserData;
