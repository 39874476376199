import { PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import { routes } from '../routing';

function useLogout(msalInstance: PublicClientApplication) {
  const instance = useMsal();
  const { accounts } = instance;
  const currentAccount = accounts[0];
  const navigate = useNavigate();

  function logout() {
    if (currentAccount) {
      msalInstance.logoutRedirect({
        account: currentAccount,
        onRedirectNavigate: () => {
          navigate(routes.login.path);
          return false;
        },
      });
    }
  }

  return logout;
}

export default useLogout;
