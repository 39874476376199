import { AxiosError } from 'axios';

import { GenericObject } from 'lynkco-up-core/dist/types/types';

import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';

const endpointUrl = '/applicant/reset-check';

async function resetDriversLicense(caseId: string, customerNumber: string): Promise<GenericObject> {
  const url = `${serviceConfig.endpoints.onfido.root}${endpointUrl}`;

  try {
    const response = await axiosInstance.post(
      url,
      {
        caseId,
      },
      {
        headers: {
          'X-CustomerNumber': customerNumber,
        },
      },
    );

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new Error(err.response?.data.message);
    }
  }

  throw new Error('Failed to manually reset drivers license');
}

export default resetDriversLicense;
