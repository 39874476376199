export type ComponentWithChildren = {
  children?: React.ReactNode | React.ReactNode[];
};

export enum DetailsPanelSize {
  XS = 'quarter',
  SMALL = 'third',
  MEDIUM = 'half',
  LARGE = 'twoThirds',
  XL = 'threeQuarters',
  FULL = 'full',
}

export type ApplicantQuery = {
  caseId: string;
  customerNumber: string;
};

export type DefinitionDetails = ComponentWithChildren & {
  label: string;
};
