import { DetailsPanelSize } from './types';

export const screenWidths = {
  small: 800,
  medium: 980,
  large: 1200,
  xl: 1680,
  xxl: 2280,
};

export function computePanelSize(): DetailsPanelSize {
  const windowWidth = window.innerWidth;
  let size = DetailsPanelSize.FULL;

  if (windowWidth >= screenWidths.small) {
    size = DetailsPanelSize.XL;
  }

  if (windowWidth >= screenWidths.medium) {
    size = DetailsPanelSize.LARGE;
  }

  if (windowWidth >= screenWidths.large) {
    size = DetailsPanelSize.MEDIUM;
  }

  if (windowWidth >= screenWidths.xl) {
    size = DetailsPanelSize.SMALL;
  }

  if (windowWidth >= screenWidths.xxl) {
    size = DetailsPanelSize.XS;
  }

  return size;
}

export function getMarketName(marketCode: string): string {
  switch (marketCode.toLowerCase()) {
    case 'se':
      return 'Sweden';
    case 'nl':
      return 'Netherlands';
    case 'be':
      return 'Belgium';
    case 'de':
      return 'Germany';
    case 'fr':
      return 'France';
    case 'it':
      return 'Italy';
    case 'es':
      return 'Spain';
    default:
      return marketCode;
  }
}
