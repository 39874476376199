type RouteDefinition = {
  name: string;
  path: string;
};

type RouteDefinitions = {
  [key: string]: RouteDefinition;
};

const paths = {
  root: '/',
  home: '/dashboard',
  gdpr: 'gdpr',
  applicants: 'applicants',
};

const routeDefinitions: RouteDefinitions = {
  login: {
    name: 'Login',
    path: paths.root,
  },
  home: {
    name: 'Home',
    path: paths.home,
  },
  gdpr: {
    name: 'GDPR',
    path: paths.gdpr,
  },
  applicants: {
    name: 'Applicants',
    path: paths.applicants,
  },
};

export default routeDefinitions;
