import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider } from 'react-query';

import App from './App';
import { queryClient, authService } from './services/shared';

import './tailwind.css';
import '../node_modules/lynkco-up-core/dist/index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={authService}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
);

console.info(`Current version: ${process.env.REACT_APP_CURRENT_VERSION}`);
