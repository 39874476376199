import { Route, Routes } from 'react-router-dom';

import { NotFound404 } from 'lynkco-up-core';

import routes from './routeDefinitions';
import { GDPR, Applicants, Home } from '../modules';
import { RouteGuard, Layout } from '../components';
import { Login } from '../modules';

function AppRoutes() {
  return (
    <Routes>
      <Route
        path={routes.home.path}
        element={
          <RouteGuard>
            <Layout />
          </RouteGuard>
        }>
        <Route index path={routes.home.path} element={<Home />} />
        <Route path={routes.gdpr.path} element={<GDPR />} />
        <Route path={routes.applicants.path} element={<Applicants />} />
      </Route>
      <Route path={routes.login.path} element={<Login />} />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
}

export { routes };
export default AppRoutes;
