import { DefinitionDetails } from '../../types';

function RowDefinitionDetails({ label, children }: DefinitionDetails) {
  return (
    <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
      <dt className="font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 flex  text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">{children}</span>
      </dd>
    </div>
  );
}

export default RowDefinitionDetails;
