import { useEffect, useState } from 'react';

import { computePanelSize } from '../utils';

function usePanelResize() {
  const [panelSize, setPanelSize] = useState(computePanelSize());

  useEffect(() => {
    function resizeDetailsPanel() {
      setPanelSize(computePanelSize());
    }

    window.addEventListener('resize', resizeDetailsPanel);
    return () => {
      window.removeEventListener('resize', resizeDetailsPanel);
    };
  });

  return panelSize;
}

export default usePanelResize;
