import { useContext, useEffect, useRef, useState } from 'react';

import { Input, Button, GlobalContext, Alert } from 'lynkco-up-core';
import { ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { Divider } from '../../../components';
import { InputWrapper } from '../../shared';
import { getMaxLengthErrorMessage } from '../utils';
import { resetDriversLicense } from '../../../services/Applicants';
import { ApplicantQuery } from '../../../types';

function ResetChecksContent({ applicantQuery }: { applicantQuery: ApplicantQuery }) {
  const { alert, loading } = useContext(GlobalContext);
  const [customerNumber, setCustomerNumber] = useState(applicantQuery.customerNumber);
  const [caseId, setCaseId] = useState(applicantQuery.caseId);
  const [isCustomerSuggested, setIsCustomerSuggested] = useState(applicantQuery.customerNumber);
  const [formErrors, setFormErrors] = useState({
    customerNumber: false,
    caseId: false,
  });
  const [resultError, setResultError] = useState<null | string>(null);
  const isSubmitDisabled = !customerNumber || !caseId || Object.values(formErrors).some(error => error);
  const inputRef = useRef<null | HTMLInputElement>(null);

  async function handleSubmit() {
    if (isSubmitDisabled) {
      return;
    }

    loading.show();

    try {
      await resetDriversLicense(caseId, customerNumber);
      alert.show('User drivers license checks reset.', 'success');
    } catch (error) {
      setResultError(error as string);
    }

    loading.hide();
  }

  function handleDismissCustomerSuggestion() {
    setIsCustomerSuggested('');
  }

  function handleDismissResultError() {
    setResultError(null);
  }

  const handleChangeCustomerNumber: ReactInputEventHandler = e => {
    setCustomerNumber(e.currentTarget.value);
  };

  const handleChangeCaseId: ReactInputEventHandler = e => {
    setCaseId(e.currentTarget.value);
  };

  useEffect(() => {
    setFormErrors({
      customerNumber: customerNumber.length > 50,
      caseId: caseId.length > 50,
    });
  }, [customerNumber, caseId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div>
      <h2 className="font-medium text-xl mb-7">Reset drivers license checks</h2>
      {resultError && (
        <Alert
          type="error"
          heading="There was an error while trying to reset user"
          body={`${resultError}`}
          onDismiss={handleDismissResultError}
        />
      )}
      {isCustomerSuggested && (
        <Alert
          type="success"
          heading="Suggested customer"
          body={`We selected customer ${applicantQuery.customerNumber} for you`}
          onDismiss={handleDismissCustomerSuggestion}
        />
      )}
      <Divider />
      <InputWrapper label="Customer number*">
        <Input
          type="text"
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCustomerNumber}
          errorText={getMaxLengthErrorMessage(50)}
          hasError={formErrors.customerNumber}
          value={customerNumber}
          ref={inputRef}
        />
      </InputWrapper>
      <Divider />
      <InputWrapper label="Case ID*">
        <Input
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCaseId}
          errorText={getMaxLengthErrorMessage(50)}
          value={caseId}
          hasError={formErrors.caseId}
        />
      </InputWrapper>
      <Divider />
      <div className="flex justify-end mt-7">
        <Button onClick={() => handleSubmit()} isDisabled={isSubmitDisabled}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ResetChecksContent;
