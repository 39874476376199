import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Button, Feeds, Icon } from 'lynkco-up-core';
import { Record } from 'lynkco-up-core/dist/types/components/Feeds';
import { GenericFunction } from 'lynkco-up-core/dist/types/types';

import { Divider } from '../../../components';
import RowDefinitionDetails from '../../../components/RowDefinitionDetails';
import { UserData } from '../../../services/GDPR/types';
import { getMarketName } from '../../../utils';
import OnfidoVerificationStatus from './OnfidoVerificationStatus';

type ResultsProps = {
  data: UserData;
  openRedirectToOnfido: GenericFunction;
};

dayjs.extend(utc);

const dateFormat = 'YYYY-MM-DD HH:mm';

const fallbackRecord: Record = {
  id: 'fallback',
  content: 'No checks found',
  icon: 'close',
  iconBackground: 'danger',
};

function getIconType(flag: boolean) {
  return flag ? 'check' : 'close';
}

function getIconBackground(flag: boolean) {
  return flag ? 'primary' : 'danger';
}

function buildRecords(data: UserData): Record[] {
  const driversLicenseData = data.lynkCoApplicant.checks[0];

  if (!driversLicenseData) {
    return [fallbackRecord];
  }

  const {
    id = 0,
    validDocumentType,
    validDocumentExpirationDate,
    validIssuingCountry,
    validDateOfBirth,
    documentAuthenticity,
    facialSimilarity,
    watchlist,
  } = driversLicenseData;

  return [
    {
      id: `docType-${id}`,
      content: 'Document type is drivers license',
      icon: getIconType(validDocumentType),
      iconBackground: getIconBackground(validDocumentType),
    },
    {
      id: `expDate-${id}`,
      content: 'Document did not expire',
      icon: getIconType(validDocumentExpirationDate),
      iconBackground: getIconBackground(validDocumentExpirationDate),
    },
    {
      id: `issuingCountry-${id}`,
      content: 'Document issuing country',
      icon: getIconType(validIssuingCountry),
      iconBackground: getIconBackground(validIssuingCountry),
    },
    {
      id: `dob-${id}`,
      content: 'User age is above 21',
      icon: getIconType(validDateOfBirth),
      iconBackground: getIconBackground(validDateOfBirth),
    },
    {
      id: `docAuthenticity-${id}`,
      content: 'Document authenticity',
      icon: getIconType(documentAuthenticity),
      iconBackground: getIconBackground(documentAuthenticity),
    },
    {
      id: `facialSimilarity-${id}`,
      content: 'Facial similarity between document and video',
      icon: getIconType(facialSimilarity),
      iconBackground: getIconBackground(facialSimilarity),
    },
    {
      id: `watchlist-${id}`,
      content: 'User is not politically exposed or appearing on a watchlist',
      icon: getIconType(watchlist),
      iconBackground: getIconBackground(watchlist),
    },
  ];
}

function Results({ data, openRedirectToOnfido }: ResultsProps) {
  const records = buildRecords(data);
  const carSharingEnabled = data.carSharingData.carSharing.cccSubscribeStatus;

  function handleOpenRedirectToOnfidoDialog() {
    openRedirectToOnfido();
  }

  return (
    <div>
      <h3 className="text-xl mt-10">Results</h3>
      <Divider />
      <RowDefinitionDetails label="Name">
        {data.onfidoApplicant.firstName} {data.onfidoApplicant.lastName}
      </RowDefinitionDetails>
      <Divider />
      <RowDefinitionDetails label="Status">
        <OnfidoVerificationStatus status={data.lynkCoApplicant.onfidoVerificationStatus} />
      </RowDefinitionDetails>
      <Divider />
      <RowDefinitionDetails label="Signin up in">{getMarketName(data.lynkCoApplicant.marketCode)}</RowDefinitionDetails>
      <Divider />
      <RowDefinitionDetails label="Customer ID">{data.lynkCoApplicant.customerId}</RowDefinitionDetails>
      <Divider />
      <RowDefinitionDetails label="Application start">
        {dayjs.utc(data.lynkCoApplicant.createdAt).format(dateFormat)} UTC
      </RowDefinitionDetails>
      <Divider />
      <div className="flex justify-between items-center mt-10 mb-5">
        <h3 className="text-xl">Drivers license</h3>
        <Button onClick={handleOpenRedirectToOnfidoDialog} variant="white" size="small">
          <Icon name="open_in_new" size="small" padding={0} />
          <span className="pl-2">Open in Onfido</span>
        </Button>
      </div>
      <Feeds records={records} />
      <Divider />
      <h3 className="text-xl mt-10 mb-5">Permissions</h3>
      <Feeds
        records={[
          {
            id: `permissions`,
            content: `Car sharing has ${carSharingEnabled ? 'been' : 'not been'} enabled`,
            icon: getIconType(carSharingEnabled),
            iconBackground: getIconBackground(carSharingEnabled),
          },
        ]}
      />
    </div>
  );
}

export default Results;
//100129052
