import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { Alert, SlideOver } from 'lynkco-up-core';

import { PageHeading, ActionPanel } from '../../components';
import { usePanelResize } from '../../hooks';
import { DeleteUserDataContent, GetUserDataContent } from '../../parts/GDPR';

enum OperationType {
  GET_USER_DATA = 'GET_USER_DATA',
  DELETE_USER_DATA = 'DELETE_USER_DATA',
  NONE = 'NONE',
}

function Gdpr() {
  const [isSlideOverOpen, setSlideOverOpen] = useState(false);
  const [operationType, setOperationType] = useState(OperationType.NONE);
  const slideOverSize = usePanelResize();

  function handleOpenGetUserData() {
    setOperationType(OperationType.GET_USER_DATA);
    setSlideOverOpen(true);
  }

  function handleOpenDeleteUserData() {
    setOperationType(OperationType.DELETE_USER_DATA);
    setSlideOverOpen(true);
  }

  function handleCloseSlideOver() {
    setSlideOverOpen(false);
  }

  useEffect(() => {
    if (!isSlideOverOpen) {
      setOperationType(OperationType.NONE);
    }
  }, [isSlideOverOpen]);

  return (
    <div>
      <Helmet>
        <title>GDPR - Car Sharing</title>
      </Helmet>
      <PageHeading title="GDPR requests" description="Delete or get customer data" />
      <div className="pt-7 px-7">
        <Alert
          type="info"
          heading="These actions only apply to Car Sharing"
          body="The actions in this tool for getting or deleting customer data only applies to car sharing. It does not include all other systems."
        />
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-1 gap-7 p-7">
        <ActionPanel
          title="Get user data"
          description="Get all data we have on the user saved from signing up for car sharing as a borrower and from Onfido."
          buttonText="Get started"
          onClick={handleOpenGetUserData}
          iconName={'person'}
          iconBg={'bg-blue-500'}
        />
        <ActionPanel
          title="Delete user data"
          description="Delete all data we have on the user saved from signing up for car sharing as a borrower and from Onfido."
          buttonText="Get started"
          onClick={handleOpenDeleteUserData}
          iconName={'person_remove'}
          iconBg={'bg-red-500'}
        />
      </div>
      <SlideOver
        isOpen={isSlideOverOpen && operationType === OperationType.GET_USER_DATA}
        onCancel={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        size={slideOverSize}>
        <GetUserDataContent />
      </SlideOver>
      <SlideOver
        isOpen={isSlideOverOpen && operationType === OperationType.DELETE_USER_DATA}
        onCancel={handleCloseSlideOver}
        onClose={handleCloseSlideOver}
        size={slideOverSize}>
        <DeleteUserDataContent />
      </SlideOver>
    </div>
  );
}

export default Gdpr;
