import { useContext, useEffect, useRef, useState } from 'react';

import { Input, Button, GlobalContext, Alert, Dialog } from 'lynkco-up-core';
import { ReactInputEventHandler } from 'lynkco-up-core/dist/types/types';

import { Divider } from '../../../components';
import { InputWrapper } from '../../shared';
import { ApplicantQuery } from '../../../types';
import { getUserData } from '../../../services/GDPR';
import Results from './Results';
import { UserData } from '../../../services/GDPR/types';
import { buildOnfidoUrl } from './utils';

const getMaxLengthErrorMessage = (limit: number) => `This value cannot exceed ${limit} characters.`;

function ApplicationProgressContent({ applicantQuery }: { applicantQuery: ApplicantQuery }) {
  const { alert, loading } = useContext(GlobalContext);
  const [customerNumber, setCustomerNumber] = useState(applicantQuery.customerNumber);
  const [isCustomerSuggested, setIsCustomerSuggested] = useState(applicantQuery.customerNumber);
  const [userData, setUserData] = useState<null | UserData>(null);
  const [formErrors, setFormErrors] = useState({
    customerNumber: false,
  });
  const [resultError, setResultError] = useState<string | null>(null);
  const isSubmitDisabled = !customerNumber || Object.values(formErrors).some(error => error);
  const inputRef = useRef<null | HTMLInputElement>(null);

  const [isRedirectToOnfidoDialogOpen, setIsRedirectToOnfidoDialogOpen] = useState(false);

  const handleChangeCustomerNumber: ReactInputEventHandler = e => {
    setCustomerNumber(e.currentTarget.value);
  };

  function handleDismissResultError() {
    setResultError(null);
  }

  function handleDismissCustomerSuggestion() {
    setIsCustomerSuggested('');
  }

  function handleOpenRedirectToOnfidoDialog() {
    setIsRedirectToOnfidoDialogOpen(true);
  }

  function handleCloseRedirectToOnfidoDialog() {
    setIsRedirectToOnfidoDialogOpen(false);
  }

  function handleRedirectToOnfido() {
    const url = buildOnfidoUrl(userData?.onfidoApplicant.id);

    handleCloseRedirectToOnfidoDialog();
    window.open(url, '_blank');
  }

  async function handleSubmit() {
    if (isSubmitDisabled) {
      return;
    }

    loading.show();

    try {
      // Temporary hardcoded case id
      const data = await getUserData('0000', customerNumber);
      setUserData(data);
      alert.show('Data successfully retrieved.', 'success');
    } catch (error) {
      setResultError(error as string);
    }

    loading.hide();
  }

  useEffect(() => {
    setFormErrors({
      customerNumber: customerNumber.length > 50,
    });
  }, [customerNumber]);

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, []);

  return (
    <div>
      <h2 className="font-medium text-xl mb-7">User application progress</h2>
      {resultError && (
        <Alert
          type="error"
          heading="There was an error while trying to get user data"
          body={`${resultError}`}
          onDismiss={handleDismissResultError}
        />
      )}
      {isCustomerSuggested && (
        <Alert
          type="success"
          heading="Suggested customer"
          body={`We selected customer ${applicantQuery.customerNumber} for you`}
          onDismiss={handleDismissCustomerSuggestion}
        />
      )}
      <Divider />
      <InputWrapper label="Customer number*">
        <Input
          ref={inputRef}
          type="text"
          extraClasses="basis-2/3 -mt-1"
          onChange={handleChangeCustomerNumber}
          errorText={getMaxLengthErrorMessage(50)}
          hasError={formErrors.customerNumber}
          value={customerNumber}
        />
      </InputWrapper>
      <Divider />
      <div className="flex justify-end mt-7">
        <Button onClick={() => handleSubmit()} isDisabled={isSubmitDisabled}>
          Submit
        </Button>
      </div>
      {userData && <Results data={userData} openRedirectToOnfido={handleOpenRedirectToOnfidoDialog} />}
      <Dialog
        icon="info"
        isOpen={isRedirectToOnfidoDialogOpen}
        title="Redirecting to stripe"
        onCancel={handleCloseRedirectToOnfidoDialog}
        onConfirm={handleRedirectToOnfido}
        onClose={handleCloseRedirectToOnfidoDialog}
        cancelText="Cancel"
        confirmText="Proceed">
        We will open a new tab taking you to the onfido dashboard.
      </Dialog>
    </div>
  );
}

export default ApplicationProgressContent;
