import axios from 'axios';

import getToken from './getToken';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async config => {
    const token = await getToken();

    if (!token) {
      throw new Error('Failed to get token');
    }

    if (!config.headers) {
      config.headers = {};
    }

    config.headers['X-Auth-Token'] = token;
    config.headers['api-version'] = '1';

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
