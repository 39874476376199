type OnfidoVerificationStatusProps = {
  status: string;
};

function getOnfidoVerificationStatusElements(status: string) {
  switch (status) {
    case 'FINISHED_SUCCESSFULLY':
      return ['primary', 'Finished'];
    case 'FINISHED_SUCCESSFULLY_MANUALLY_UPDATED':
      return ['primary', 'Finished - manually updated'];
    case 'FINISHED_WITH_ERRORS':
      return ['danger', 'Finished with errors'];
    case 'IN_PROGRESS':
      return ['blue', 'In progress'];
    case 'NOT_VERIFIED':
      return ['warning', 'Not verified'];
    default:
      return [undefined, undefined];
  }
}

function OnfidoVerificationStatus({ status }: OnfidoVerificationStatusProps) {
  const [color = 'gray', text = status] = getOnfidoVerificationStatusElements(status);
  const backgroundColor = `bg-${color}-500`;

  return (
    <div className="flex items-center">
      <div className={`rounded-full w-3 h-3 mr-2 ${backgroundColor}`} />
      {text}
    </div>
  );
}

export default OnfidoVerificationStatus;
