import { GenericObject } from 'lynkco-up-core/dist/types/types';

import { axiosInstance } from '../shared';
import { serviceConfig } from '../../config';
import { AxiosError } from 'axios';

const endpointUrl = '/applicant';

async function deleteUserData(caseId: string, customerNumber: string): Promise<GenericObject> {
  const url = `${serviceConfig.endpoints.onfido.root}${endpointUrl}`;

  try {
    const response = await axiosInstance.delete(url, {
      headers: {
        'X-CustomerNumber': customerNumber,
      },
      data: {
        caseId,
      },
    });

    return response.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response) {
      throw new Error(err.response?.data.message);
    }

    throw new Error('Failed to delete user data');
  }
}

export default deleteUserData;
